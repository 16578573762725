import * as React from "react";
import {useEffect, useState} from "react";
import {useStaticQuery, graphql, Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

import CleanDataParser from "../helpers/editorjs-parser";

export default function BlogFilter(props: any) {
  const {allStrapiArticle, allStrapiArticleCategory} =
  useStaticQuery(graphql`
      query {
          allStrapiArticle {
              nodes {
                  article_categories {
                      id
                      title
                      urlPart
                      strapi_id
                  }
                  id
                  urlPart
                  titleShort
                  title
                  parentCategory
                  parentCategorySlug
                  shortContent {
                      data {
                          id
                          shortContent
                      }
                  }
                  coverImage {
                      alternativeText
                      id
                      localFile {
                          childImageSharp {
                              gatsbyImageData(
                                  placeholder: NONE,
                                  width: 700,
                                  height: 600,
                                  outputPixelDensities: 1,
                                  transformOptions: {cropFocus: CENTER, fit: COVER}
                              )
                          }
                          url
                      }
                  }
              }
          }
          allStrapiArticleCategory {
              nodes {
                  title
                  urlPart
              }
          }
      }
  `);

  const [articles, setArticles] = useState<any>([]);
  const [articleCategories, setArticleCategories] = useState<any>([]);
  const [filtredCategory, setFiltredCategory] = useState(articles);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState(articles);
  const firstItem = [
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150
  ];

  const [error, setError] = useState(null);

  useEffect(() => {
    setArticles(allStrapiArticle.nodes);
    setArticleCategories(allStrapiArticleCategory.nodes);
  }, []);

  const searchItems = (searchValue: any) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = articles.filter((item: any) => {
        return item.title
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(articles);
    }
  };

  function handleCategory(e: any) {
    setSearchInput("");
    const typeCategory = e.target.value;
    if (typeCategory !== "all") {
      const filteredArticles = articles.filter((item: any) =>
        item.article_categories && item.article_categories.length > 0
          ? item.article_categories.some(
            (oneCat: any) => oneCat.urlPart == typeCategory
          )
          : []
      );

      setFiltredCategory(filteredArticles);
    } else {
      setFiltredCategory(articles);
    }

    const buttonsList = Array.from(
      document.getElementsByClassName("button-item")
    );

    buttonsList.forEach((buttonItem) => {
      buttonItem.classList.remove("button-item--active", "bg-white", "text-deepblue");
      buttonItem.classList.add("button-item--inactive", "bg-transparent", "text-white");
    });
    e.currentTarget.classList.add("button-item--active", "bg-white", "text-deepblue");
    e.currentTarget.classList.remove("button-item--inactive", "bg-transparent", "text-white");
  }

  function handleChange(e: any) {
    searchItems(e.target.value);
    const buttonsList = Array.from(
      document.getElementsByClassName("button-item")
    );

    buttonsList.forEach((buttonItem) => {
      buttonItem.classList.remove("button-item--active");
      buttonItem.classList.add("button-item--inactive");
    });
  }

  return (
    <div className="filter-section filter-section--articles pb-5 pl-xl-4 pr-xl-4">
      <div className="filter-wrapper mb-0 pb-xl-5">
        <div
          className={"search-box search-box--articles d-flex flex-column flex-lg-row justify-content-center row no-gutters mb-0 mb-xl-2 pb-4 pb-xl-3 pl-4 pr-4 pl-xl-0 pr-xl-0"}>
          <div className="col-12 pb-2">
            <input id="search-input" type="text" placeholder="search articles" value={searchInput}
                   onChange={handleChange} autoComplete="off"/>
            <span></span>
          </div>
        </div>
        <div className="button-list button-list--primary d-flex mb-4 mb-xl-0 bg-deepblue">
          <button value={"all"}
                  className={"d-flex align-items-center button-item justify-content-center button-item--active bg-white text-deepblue"}
                  onClick={handleCategory}>All articles
          </button>
          {articleCategories.map((item: any, index: any) => (
            <button key={index} value={item.urlPart}
                    className={"d-flex align-items-center justify-content-center button-item button-item--inactive bg-transparent text-white"}
                    onClick={handleCategory}>{item.title}</button>
          ))}
        </div>
        <div
          className="search-result articles-grid row no-gutters justify-content-between mt-4 pl-4 pr-4 pl-xl-0 pr-xl-0">
          {searchInput.length === 0 && filtredCategory?.length === 0 ? (articles.map((item: any, index: any) => {
              const articleShortText = (
                <div>{item.shortContent && item.shortContent?.data?.shortContent && JSON.parse(item.shortContent.data.shortContent).blocks.map((block: any, idx: any) => CleanDataParser(block, idx))}</div>
              );
              const coverImage = item.coverImage ? item.coverImage.localFile.url : null;
              const itemSlug = item.parentCategorySlug !== null ? "/guidance/" + item.parentCategorySlug + "/" + item.urlPart + "/" : "/guidance/" + item.urlPart + "/";
              return (
                <div key={index}
                     className={firstItem.indexOf(index) !== -1 ? "col-12 col-md-6 col-xl-8 article-col mb-4" : "col-12 col-md-6 col-xl-4 article-col mb-4"}>
                  <Link
                    to={itemSlug}
                    state={{data: item, articles: articles,}}
                    className="follow-link d-block text-darkblue">
                    <div
                      className={firstItem.indexOf(index) !== -1 ? "article-wrapper article-wrapper--horizontal d-flex flex-column flex-xl-row bg-white" : "article-wrapper article-wrapper--vertical d-flex flex-column bg-white"}>
                      {coverImage && <GatsbyImage style={{height: "100%", overflow: "visible"}}
                                                  image={item.coverImage.localFile.childImageSharp.gatsbyImageData}
                                                  alt={item.titleShort ? item.titleShort : item.title} loading="lazy"/>}
                      <div className="article-content d-flex flex-column justify-content-between">
                        <div className="text-deepblue">
                          <h2
                            className="text-deepblue article-content__title mb-4">{item.titleShort ? item.titleShort : item.title}</h2>
                          <div>{articleShortText}</div>
                        </div>
                        <u>Read more</u>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
          ) : searchInput.length > 2 && filteredResults?.length > 0 ? (filteredResults.map((item: any, index: any) => {
              const articleShortText = (
                <div>{item.shortContent?.data?.shortContent && JSON.parse(item.shortContent.data.shortContent).blocks.map((block: any, idx: any) => CleanDataParser(block, idx))}</div>
              );
              const coverImage = item.coverImage ? item.coverImage.localFile.url : null;
              const itemSlug = item.parentCategorySlug !== null ? "/guidance/" + item.parentCategorySlug + "/" + item.urlPart + "/" : "/guidance/" + item.urlPart + "/";
              return (
                <div key={index}
                     className={firstItem.indexOf(index) !== -1 ? "col-12 col-md-6 col-xl-8 article-col mb-4" : "col-12 col-md-6 col-xl-4 article-col mb-4"}>
                  <Link
                    to={itemSlug}
                    state={{data: item, articles: articles,}}
                    className="follow-link d-block text-darkblue">
                    <div
                      className={firstItem.indexOf(index) !== -1 ? "article-wrapper article-wrapper--horizontal d-flex flex-column flex-xl-row bg-white" : "article-wrapper article-wrapper--vertical d-flex flex-column bg-white"}>
                      {coverImage && <GatsbyImage style={{height: "100%", overflow: "visible"}}
                                                  image={item.coverImage.localFile.childImageSharp.gatsbyImageData}
                                                  alt={item.titleShort ? item.titleShort : item.title} loading="lazy"/>}
                      <div className="article-content d-flex flex-column justify-content-between">
                        <div className="text-deepblue">
                          <h2
                            className="text-deepblue article-content__title mb-4">{item.titleShort ? item.titleShort : item.title}</h2>
                          <div>{articleShortText}</div>
                        </div>
                        <u>Read more</u>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
          ) : searchInput.length > 2 && filteredResults?.length === 0 ? (
            <div className={"col-12 article-col mb-4 d-flex justify-content-center align-items-center"}>
              <h1 className={"text-white"}>Your search for {searchInput} gave 0 hits</h1>
            </div>
          ) : (
            filtredCategory?.length > 0 && filtredCategory.map((item: any, index: any) => {
              const articleShortText = (
                <>
                  {item.shortContent?.data?.shortContent && JSON.parse(item.shortContent.data.shortContent).blocks.map((block: any, idx: any) => CleanDataParser(block, idx))}
                </>
              );
              const coverImage = item.coverImage ? item.coverImage.localFile.url : null;
              const itemSlug = item.parentCategorySlug !== null ? "/guidance/" + item.parentCategorySlug + "/" + item.urlPart + "/" : "/guidance/" + item.urlPart + "/";
              return (
                <div key={index}
                     className={firstItem.indexOf(index) !== -1 ? "col-12 col-md-6 col-xl-8 article-col mb-4" : "col-12 col-md-6 col-xl-4 article-col mb-4"}>
                  <Link
                    to={itemSlug}
                    state={{data: item, articles: articles,}}
                    className="follow-link d-block text-darkblue">
                    <div
                      className={firstItem.indexOf(index) !== -1 ? "article-wrapper article-wrapper--horizontal d-flex flex-column flex-xl-row bg-white" : "article-wrapper article-wrapper--vertical d-flex flex-column bg-white"}>
                      {coverImage && <GatsbyImage style={{height: "100%", overflow: "visible"}}
                                                  image={item.coverImage.localFile.childImageSharp.gatsbyImageData}
                                                  alt={item.titleShort ? item.titleShort : item.title} loading="lazy"/>}
                      <div className="article-content d-flex flex-column justify-content-between">
                        <div className="text-deepblue">
                          <h2
                            className="text-deepblue article-content__title mb-4">{item.titleShort ? item.titleShort : item.title}</h2>
                          <div>{articleShortText}</div>
                        </div>
                        <u>Read more</u>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}