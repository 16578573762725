import * as React from "react";
import {graphql, PageProps} from "gatsby"

import Layout from "../components/layout";
import Hero from '../components/hero';
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import BlogFilter from "../components/blog-filter";
import TemplateHead from "../components/template-head";
import HeroContentHeading from "../components/hero-content-heading";


type DataProps = {
  strapiAdvisor: any,
}

export default function Blog({data: {strapiAdvisor}}: PageProps<DataProps>) {
  const advisor = strapiAdvisor

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--blog pl-4 pr-4"
        bgImageClassName="hero-image hero-image--blog"
        contentClassName="hero-content hero-content--blog"
        imageGlow={"/images/glow.png"}
        altImages="Fenix Funeral Directors"
        breadcrumb={[{name: 'Fenix Home', slug: '/'}, {name: 'Guidance', slug: '/guidance'}]}
      >
        <HeroContentHeading
          headingTitle="Guidance"
          headingContent="Knowledge articles about everything related to funerals and law"
          advisorData={advisor}
        />
      </Hero>
      <BlogFilter/>
      <TrustpilotCarousel/>
    </Layout>
  )
}

export function Head() {
  return (
    <TemplateHead
      title="Guidance - Fenix Funeral"
      shortlink="https://fenixfuneral.co.uk/guidance/"
      alternate="https://fenixfuneral.co.uk/guidance/"
      canonical="https://fenixfuneral.co.uk/guidance/"
      og={{
        url: "https://fenixfuneral.co.uk/guidance/",
        title: "Arranging a funeral Fenix Funeral Directors"
      }}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement":
          [
            {
            "@type": "ListItem",
            "position": "1",
            "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
            },
            {
            "@type": "ListItem",
            "position": "2",
            "name": "Guidance","item": "https://fenixfuneral.co.uk/guidance/"
            }
          ]
        }`
      }
    />
  );
}

export const pageQuery = graphql`
    query {
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 400,
                            height: 400, 
                            placeholder: NONE,
                            outputPixelDensities: 1
                        )
                    }
                }
            }
        }
    }
`;